.settingsPageHeader {
  display: flex;
  background-color: #fff;
  height: 128px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 50px;
}
.form {
  background-color: #fff;
  height: calc(100% - 170px);
  margin: 19px 18px;
}
.button{
  width: 70%;
}

.formItemAlignment{
  margin: 0;
}

.row {
  flex-direction: column;
  & > div {
    margin-bottom: 20px;
    & > div > div > :nth-of-type(2) {
      position: absolute;
      bottom: -25px;
    }
  }
}

.input {
  border-radius: 5px;
  height: 40px;
}

.buttonAlign{
  width: 100%;
}

.title {
  text-align: center;
  height: 100%;
}

.settingsForm {
  height: 100%;
}
.authForm {
  min-width: 450px;
  height: 100%;
}
