.pageHeader {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  padding: 16px 24px 16px 24px!important;
  .wrapper {
    overflow: hidden;
    width: 100%;
    .scroll {
      overflow: auto;
    }
  }
  [class~='ant-page-header-heading'] {
    width: 100%;
    [class~='ant-page-header-heading-left'] {
      width: 100%;
      [class~='ant-page-header-heading-title'] {
        width: 100%;
      }
    }
  }
  [class~='ant-row'] {
    display: flex;
    flex-direction: column;
  }
  .title {
    padding-top: 22px !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}
