.group {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  & > div:nth-child(1) > div > div {
    white-space: pre;
  }
  h3 {
    margin: 0!important;
    text-align: center;
    height: 50px!important;
    width: 225px!important;
    display: flex;
    align-items: flex-end;
  }
  label:first-child {
    margin-right: 20px;
  }
  label {
    height: 200px;
    width: 200px;
  }
}