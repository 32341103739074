.tablePlacement {
    width: 85%;
    margin-left: 4%;
    margin-bottom: 10px;
}

.blankSpace {
    height: 60px;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    margin: 12px 20px 12px 20px;
    background-color: #fff;
    position: relative;
    & > div > .userAssessment {
        width: 90%;
        margin: 5% auto 0 auto;
    }
    [class~='ant-steps-horizontal'] {
        width: 90%;
        margin: 0 auto;
        padding-top: 4%;
    }
    .actionButtons {
        width: 81% !important;
        margin: auto auto 0 auto;
        padding-bottom: 20px;
    }
}
.hideActionButton {
    display: none;
}
.vorbereitungContainer {
    width: 90%;
    margin: 5% auto 0 auto;
    [class~='ant-tabs-content-holder'] {
        border-left: none;
    }
}
.datePlacement {
    height: 600px;
}
.timeframeContainer {
    width: 100%;
    .datePickerCard {
        margin-bottom: 71px;
    }
    .timeframeForm {
        [class~='ant-form-item'] {
            margin-bottom: 0;
        }
    }
}
.reminders {
    [class~='ant-checkbox-group'] {
        display: flex;
        flex-direction: column;
    }
}
