@import "src/styles/variables.scss";

.userAssessment {
  margin: 10px auto;
  background-color: #fff;
  width: 98%;
  min-height: 650px;
  height: calc(100vh - 85px);
  padding: 40px 20px 40px 20px;
  position: relative;
  [class~='ant-typography'] {
    margin-bottom: 20px;
  }
  .userProgress {
    display: flex;
    flex-direction: column;
    align-items: center;
    .userIcon {
      font-size: 80px;
      color: $primary;
    }
  }
  .disabled {
    background-color: #fafafa;
    &:hover {
      cursor: not-allowed !important;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
  .category {
    justify-content: space-between;
    padding: 12px 16px;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    [class~='ant-progress'] {
      width: 100%;
      font-size: 14px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      [class~='ant-progress-steps-outer'] {
        width: 100%;
        [class~='ant-progress-text'] {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          text-align: end;
        }
      }
      [class~='ant-progress-text'] {
        text-align: end;
      }
    }
    & > div:first-child {
      display: flex;
      align-items: center;
      [class~='ant-image'] {
        margin-right: 10px;
      }
    }
    & > div:last-child {
      display: flex;
      align-items: center;
    }
    .question {
      color: rgba(0,0,0,.25);
    }
  }
  .category:hover {
    cursor: pointer;
    color: #1890ff;;
  }
  .category:last-child {
    border-bottom: 1px solid #d9d9d9;
  }
  .category:first-child {
    border-top: 1px solid #d9d9d9;
  }
}
