.pageHeader {
  display: flex;
  background-color: #fff;
  width: 100%;
  padding: 16px 24px 16px 24px!important;
  height: 128px;
  & > div {
    padding: 16px 24px 16px 24px!important;
    width: 100%;
    & > div {
      width: 100%;
      span {
        width: 100%;
        div {
          & > div:nth-of-type(1) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
          }
          & > div:nth-of-type(2) {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

.statusAnalyseCardsContainer {
  margin-bottom: 20px;
}
.performanceCardsContainer {
  margin-bottom: 20px;
}
.cardContainer {
  overflow: auto;
  padding: 19px 18px;
  & > div {
    height: calc(100vh - 250px);
    width: 100%;
    & > div:nth-of-type(1) {
      padding-right: 10px;
    }
    & > div:nth-of-type(2) {
      padding-left: 10px;
    }
  }
}

.containerProjectOverview {
  padding: 19px 18px;
  overflow: hidden;
  & > div {
    height: auto;
    & > div:nth-of-type(2) {
      padding-left: 0;
      padding-right: 10px;
    }
    & > div:nth-of-type(3) {
      padding-left: 10px;
    }
  }
}

.card {
  text-align: center;
  height: 100%;
  .actionButton {
    display: flex;
    justify-content: flex-start;
  }
}

.actionButtonsContainer {
  display: flex;
  justify-content: center;
  button:first-child {
    margin-right: 10px;
  }
}

.optionText {
  color: rgba(0, 0, 0, 0.45);
  margin-left: 5px;
}
.skip {
  color: red
}
.textCenter {
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}

.question {
  &:hover {
    cursor: pointer
  }
}

.dragContainer {
  display: flex;
  align-items: center;
}
.dragIcon {
  cursor: grab;
  color: #999;
  position: relative;
  margin-right: 5px;
  span:last-child {
    position: absolute;
    left: 5px;
    bottom: 4px;
  }
}
.questionIdentifier {
  font-size: 12px;
}
.sequentialNumber {
  font-size: 12px;
}
.answerOptions {
  & > div:nth-of-type(1) {
    margin-right: 5px;
  }
}
.addBtn {
  border-radius: 50%;
  background-color: #1890ff;
  color: white;
  float: right;
  margin-top: 10px;
  margin-bottom: 45px;
}

.noBorder {
  border-bottom: none !important;
}
.answerCard {
  border: 1px solid #B1B1B1;
  height: 100%;
  [class~='ant-card-head'] {
    font-size: 14px;
    border-bottom: none;
    text-align: center;
  }
  [class~='ant-radio-wrapper'] {
    margin-right: 0;
  }
  [class~='ant-card-body'] {
    padding-top: 0;
    [class~='ant-input'] {
      cursor: pointer;
      &:hover {
        border: 1px solid #d9d9d9;
      }
      &:focus {
        box-shadow: none;
        border: 1px solid #d9d9d9;
      }
    }
    [class~='ant-radio-disabled'] {
      cursor: pointer;
      [class~='ant-radio-inner'] {
        cursor: pointer;
      }
    }
    [class~='ant-radio-disabled'] + span {
      cursor: pointer;
    }
    [class~='ant-radio-input'] {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
    border-color:  #1890ff;
  }
}
.active {
 border: 2px solid #1890ff;
}

@media screen and (max-width: 990px) {
  .performanceCardsContainer {
    flex-direction: column;
    & > div:nth-of-type(1) {
      margin-right: 0!important;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .statusAnalyseCardsContainer {
    flex-direction: column;
    & > div:nth-of-type(1) {
      margin-right: 0!important;
      margin-bottom: 10px;
    }
    & > div:nth-of-type(2) {
      margin-right: 0!important;
      margin-bottom: 10px;
    }
  }
}
