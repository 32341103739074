.main {
  position: relative;
  min-height: 600px;
}

.radio {
  margin-top: 20px;
  [class~='ant-radio-wrapper'] {
    align-items: center;
    display: inline-flex;
    padding-right: 20px;
    [class~='ant-radio'] {
      top: 0;
    }
  }
  [class~='ant-radio-inner'] {
    width: 30px;
    height: 30px;
  }
  [class~='ant-radio-inner']::after {
    top: 2px;
    left: 2px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  [class~='ant-col-21'] {
    word-break: break-word;
  }
  [class~='ant-col-3'] {
    margin-right: 10px;
    span:last-child {
      font-size: 18px;
    }
  }
}
.container {
  flex-wrap: nowrap;
  align-items: center;
  min-height: 50px;
  [class~='ant-col']:first-child {
    min-width: 161.5px;
    margin-right: 20px;
  }
  .explanation {
    font-size: 16px;
    overflow-wrap: break-word;
  }
}

.actionBtn {
  margin-top: 20px;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
