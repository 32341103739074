.form{
  height: 100%;
  .headerContact{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0;
    font-style: normal;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .bodyContact{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    row-gap: 0!important;
  }
}

.needsPadding {
  padding-bottom: 10px;
}
.formColumn {
  margin: 0 5px;
}
.submit {
  padding-top: 10px;
}