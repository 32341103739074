.row {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 650px;
}
.img {
  height: 20px;
  width: 20px;
}
.col {
  margin-left: 15px;
}
.btn {
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
.img2 {
  top: 40px;
  position: relative;
  height: 35px;
  margin: -35px;
  width: 425px;
}
.sliderRail > div:nth-of-type(1) {
  background-image: linear-gradient(to right, red, yellow, green) !important;
  left: 0;
  right: auto;
}
.title {
  text-align: center;
  & > div:nth-of-type(2) > div:nth-of-type(1) > div >  p {
   margin-bottom: 0;
   font-weight: 500;
  }
}
.img3 {
  height: 25px;
  width: 25px;
}
.content {
  image {
    height: 30px;
    width: 30px;
  }
  min-width: 460px;
  margin: auto 0;
  text-align: center;
  &:nth-of-type(1) > div > div > div{
    white-space: normal;
    text-align: center;
  }
}
