.question {
  label:after {
    content: '';
  }
  [class~='ant-form-item-control'] {
    margin-left: auto;
    flex: unset;
  }
}

.skipQuestion {
  [class~='ant-form-item-control'] {
    margin-left: auto;
    flex: unset;
  }
  label {
    color: red
  }
  label:after {
    content: '';
  }
}