.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category {
  display: flex;
  background-color: gray;
  border: 1px solid black;
}