.container {
  width: 100%;
  .assessmentTitle {
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
    .chart {
      padding-right: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      [class~='ant-typography'] {
        margin: 0
      }
      button {
        margin-top: 50px;
      }
    }
  }
}

.hidden {
  display: none;
}
.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 1px 0 1px 0;
  padding-right: 0 !important;
  .categoryImage:first-child {
    padding-top: 0;
  }
  &:hover {
    cursor: pointer;
  }
  & > div {
    padding: 10px 0 10px 0;
  }
  img {
    margin-right: 0;
  }
}

.categoryImage {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  [class~='ant-col'] {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.categoryContainer {
  flex-direction: column;
  span {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: normal;
  }
  [class~='ant-col']:last-child {
    align-items: center;
  }
}
.img {
  height: 24px;
  width: 24px;
  color: red!important;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 24px;
}

.green {
  color: rgba(82, 196, 26, 1);
}
.yellow {
  color: rgba(250, 173, 20, 1);
}
.red {
  color: #FF4D4F;
}
.gray {
  color: #cccccc;
}

.explanationGreen {
  &:hover {
    cursor: pointer;
  }
  p:first-child {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 6px;
  }
  p:nth-of-type(2) {
    color: rgba(82, 196, 26, 1);
    font-size: 24px;
    margin-bottom: 6px;
  }
  p:last-child {
    color: rgba(82, 196, 26, 1);
    font-size: 12px;
    margin-bottom: 30px;
  }
}

.explanationRed {
  &:hover {
    cursor: pointer;
  }
  p:first-child {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 6px;
  }
  p:nth-of-type(2) {
    color: #FF4D4F;
    font-size: 24px;
    margin-bottom: 6px;
  }
  p:last-child {
    color: #FF4D4F;
    font-size: 12px;
    margin-bottom: 30px;
  }
}

.explanationYellow {
  &:hover {
    cursor: pointer;
  }
  p:first-child {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 6px;
  }
  p:nth-of-type(2) {
    color: rgba(250, 173, 20, 1);;
    font-size: 24px;
    margin-bottom: 6px;
  }
  p:last-child {
    color: rgba(250, 173, 20, 1);;
    font-size: 12px;
    margin-bottom: 30px;
  }
}
