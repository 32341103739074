.container {
  margin: 12px 20px 12px 20px;
  background-color: #fff;
  position: relative;
  & > div {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    padding-top: 4%;
  }
  .tableCompare {
    margin-top: 5%;
    min-height: 627px;
  }
}
.img {
  height: 24px;
  width: 24px;
}
.answers {
  font-size: 18px;
  margin-right: 10px;
}
.btn {
  margin: 20px 0;
}

.generalData {
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}
.question:hover {
  cursor:pointer
}
.average {
  justify-content: center;
  font-size: 14px;
  .usersAnswer {
    display: flex;
    flex-wrap: wrap;
    & > div {
      display: flex;
      justify-content: center;
      min-width: 16px;
    }
    & > div:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.hidden {
  display: none;
}
.categoryQuestion {
  padding: 0 10px 0 10px;
  margin-bottom: 20px;
}
.radio {
  pointer-events: none;
  [class~='ant-radio-wrapper'] {
    align-items: center;
    display: inline-flex;
    padding-right: 20px;
    [class~='ant-radio'] {
      top: 0;
    }
  }
  [class~='ant-radio-inner'] {
    width: 30px;
    height: 30px;
  }
  [class~='ant-radio-inner']::after {
    top: 5px;
    left: 5px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

.tag:hover {
  cursor:pointer
}
.progress {
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
}
.total {
  font-weight: 500;
}
.green {
  background-color: #F6FFED;
  color: rgba(82, 196, 26, 1);
}
.yellow {
  color: rgba(250, 173, 20, 1);
}
.red {
  background-color: #FFF1F0;
  color: rgba(255, 77, 79, 1);
}
.gray {
  background-color: #FAFAFA;
  color: rgba(0, 0, 0, 0.85);
}

