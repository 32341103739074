.container {
  min-width: 460px;
  margin: auto 0;
  text-align: center;
  &:nth-of-type(2) {
    position: relative;
  }
  .img {
    width: 25px;
    height: 25px;
  }
  p {
    text-align: center;
  }
}
.img2 {
  top: 76px;
  position: relative;
  width: 410px;
  height: 35px;
  margin: -35px;
}
.sliderRail > div:nth-of-type(1) {
  background-image: linear-gradient(to right, red, yellow, green) !important;
  left: 0;
  right: auto;
}