.content {
  height: 100%;
  & > div {
    height: 100%;
    & > div:nth-of-type(2) {
      & > div {
        height: 100%;
      }
    }
  }
}
.customerTableHeader {
  display: flex;
  flex-direction: column;
  [class~='ant-checkbox-wrapper'] {
    margin-top: 5px;
    font-weight: normal;
  }
}
.formContact {
  height: 100%;
  .headerContact{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0;
    font-style: normal;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .bodyContact{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    row-gap: 0!important;
  }
}
.assessmentStatus {
  font-size: 9px;
  color: #999999;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.tabs {
  background-color: #fff;
  height: calc(100% - 70px);
  [class~='ant-tabs-content-holder'] {
    overflow: auto;
    background-color: #f3f3f3;
    padding: 15px 20px 0 20px !important;
  }
  [class~='ant-tabs-nav'] {
    padding: 0 30px;
    margin-bottom: -1px;
  }
}
.img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.countQuestions {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    color: #fff;
    background-color: #000;
  }
  & > div:first-child {
    margin-right: 10px;
  }
}
.tabPane {
  .breadcrumbs {
    padding: 25px 0 40px 0;
  }
}
.categoryImage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  [class~='ant-col'] {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:after {
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
  }
}
.activeCategory {
  background-color: #e6f7ff;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:after {
    transform: scaleY(1);
    opacity: 1;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 1px 0 1px 0;
  padding-right: 0 !important;
  width: 96px;
  &:hover {
    cursor: pointer;
  }
  & > div:first-child {
    padding: 36px 0 10px 0;
  }
  & > div {
    padding: 10px 0 10px 0;
  }
  img {
    margin-right: 0;
  }
}
.container {
  height: 100%;
  background-color: #fff;
  & > .ant-row {
    padding: 40px !important;
  }
  & > div {
    & > h2 {
      margin-bottom: 0;
      border-bottom: 1px solid #f3f3f3;
      padding: 20px;
    }
    z-index: 2;
    background-color: #fff;
  }
}
.status {
  margin-right: 10px;
}
.actionLink {
  margin-right: 10px;
  color: #1890ff;
  &:hover {
    color: #40a9ff;
    cursor: pointer;
  }
}
.downloadFilesContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  [class~='ant-col'] {
    display: flex;
    width: 100%;
    align-items: center;
    span {
      margin-left: 10px;
    }
    &:hover {
      cursor: pointer;
      color: #40a9ff;
    }
  }
}
.actionsContainer {
  display: flex;
  justify-content: flex-end;
}
.pageHeader {
  background-color: #fff;
  padding: 20px 0 10px 0!important;
  position: inherit;
  & > div:nth-of-type(1) {
    justify-content: space-evenly;
  }
}
.cardContact {
  background: white;
  padding: 30px 50px !important;
  & > div:nth-of-type(2) {
    padding: 20px 100px 60px 60px;
  }
}
.list {
  padding: 5px 10px 5px 30px;
  display: flex;
  width: 100%;
  & > div:nth-of-type(1) {
    display: flex;
    align-items: center;
  }
  &:hover {
    cursor:pointer
  }
}
.listHeader {
  display: flex;
  justify-content: flex-end;
  & > div {
    padding-right: 15px;
    & > div > div {
      margin: 0!important;
      padding-left: 10px;
    }
  }
}
.assessmentCard {
  [class~='ant-card-head'] {
    text-align: center;
  }
  [class~='ant-card-body'] {
    padding: 0 0 30px 0;
    [class~='ant-list-pagination'] {
      padding-right: 43px;
    }
  }
  .questionnaireTable {
    & > div > div > div > div > div > table > tbody > tr {
      &:hover {
        cursor: pointer;
      }
      & > td {
        border-bottom: 0 solid #f0f0f0 !important;
      }
    }
  }
}

.formGroup {
  display: flex;
  flex-direction: row;
}

.formInput {
  margin: 0 10px 8px 0;
}

.cardColumn {
  margin: 10px 0;
}
