@import "src/styles/variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-message-error {
  white-space: pre;
}
.ant-layout-sider-trigger {
  z-index: 4;
  background-color: $sideBarBackground !important;
}

//sidebar
.ant-menu-item-selected {
  [class~='ant-image'] {
    filter: invert(100%) !important;
  }
}
