.actionContainer {
  padding: 10px 0;
    & > div > div > div {
      display: flex;
      justify-content: center;
      button {
        margin: 0 auto!important;
        border-radius: 50%!important;
        height: 50px;
        width: 50px;
        border-width: 2px;
      }
    }
}

