.panel {
  & > div > img {
    margin-right: 10px;
  }
}
.option {
  z-index: -1;
  display: flex;
  justify-content: space-between;
}
.cardAddUsers {
  margin-bottom: 71px;
}
.addUserButton {
  height: 21px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 5px;
  [class~='anticon-plus'] {
   line-height: 0;
  }
}