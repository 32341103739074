.pageHeader {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  padding: 16px 24px 16px 24px!important;
  .wrapper {
    overflow: hidden;
    width: 100%;
    .scroll {
      overflow: auto;
    }
  }
  [class~='ant-page-header-heading'] {
    width: 100%;
    [class~='ant-page-header-heading-left'] {
      width: 100%;
      [class~='ant-page-header-heading-title'] {
        width: 100%;
      }
    }
  }
  [class~='ant-row'] {
    display: flex;
    flex-direction: column;
  }
  .title {
    padding-top: 22px !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}
.answerOptions {
  & > p:last-child {
    justify-content: flex-start !important;
    span {
      font-weight: bold;
      margin-right: 10px;
    }
  }
}

.usersCard {
  margin: 20px 0 20px 0;
  padding-bottom: 20px;
  white-space: pre-wrap;
}

.reasonCard {
  min-height: 200px;
  margin-top: 20px;
  textarea {
    min-height: 145px;
  }
}

.uploadFileCard {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 24px;
  [class~='ant-btn'] {
    margin-top: 10px;
  }
}

.hidden {
  display: none;
}
.warningModal {
  [class~='ant-modal-body'] {
    text-align: center;
  }
  [class~='ant-modal-footer'] {
    display: flex;
    justify-content: space-between;
    button {
      height: auto;
    }
  }
}
.questionCard {
  margin: 15px 24px 0 24px;
  .questionContainer {
    display: flex;
    flex-direction: column;
     .question {
        font-size: 16px;
        margin-bottom: 10px;
      }
    .hint {
      font-size: 13px;
    }
  }
  .questionIdentifier {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }
  .container {
    display: flex;
    align-items: center;
  }
  .saveIcon {
    font-size: 24px;
    color: #08c;
    margin-left: 10px;
    &:hover {
      cursor: pointer
    }
  }
  .booleanAnswer {
    & > div:last-child{
      display: flex;
      justify-content: flex-start !important;
    }
  }
  [class~='ant-card-body'] {
    padding-bottom: 0;
    .answerNoneContainer {
      display: flex;
      justify-content: flex-end;
    }
  }
  [class~='ant-card-actions']{
    padding: 24px 24px 24px 24px;
    border-top: none;
    & > li:first-child {
      text-align: start;
    }
    & > li:last-child {
      text-align: end;
    }
  }
}
.answerOption {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  [class~='ant-radio-wrapper'] {
    padding: 20px 0;
    display: flex;
    align-items: center;
    [class~='ant-radio'] {
      top: 0;
    }
    & > span[class~='ant-radio'] + * {
      overflow-wrap: anywhere;
    }
  }
}
.radio {
  [class~='ant-radio-wrapper'] {
    align-items: center;
    display: inline-flex;
    padding-right: 20px;
    [class~='ant-radio'] {
      top: 0;
    }
  }
  [class~='ant-radio-inner'] {
    width: 30px;
    height: 30px;
  }
  [class~='ant-radio-inner']::after {
    top: 2px;
    left: 2px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.noneEvents {
  pointer-events: none;
  [class~='ant-radio-wrapper'] {
    align-items: center;
    display: inline-flex;
    padding-right: 20px;
    [class~='ant-radio'] {
      top: 0;
    }
  }
  [class~='ant-radio-inner'] {
    width: 30px;
    height: 30px;
  }
  [class~='ant-radio-inner']::after {
    top: 2px;
    left: 2px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
