.homePageContainer {
  padding: 30px;
  background-color: #fff;
  margin: 15px 20px 0 20px;
  height: 86vh;
}
.buttonAlign{
  width: 25%;
}
.illustrationAlign{
  width: 30%;
}
.divIllustrationAlign{
  text-align: center;
  margin-top: 120px;
  margin-bottom: 50px;
}
.divButtonAlign{
  text-align: center;
}
.clientHomePage {
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 282px);
  margin: 15px;
  background-color: #fff;
  [class~='ant-col'] {
    display: flex;
    justify-content: center;
  }
}
.pageHeader {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  padding: 16px 24px 16px 24px!important;
  [class~='ant-row'] {
    display: flex;
    flex-direction: column;
  }
  .title {
    text-transform: uppercase;
    text-align: center;
    padding-top: 22px !important;
    font-weight: 500;
    font-size: 20px;
  }
  .welcome {
    font-weight: bolder;
    font-size: 14px;
    line-height: 22px;
  }
  .explanation {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 22px;
    white-space: break-spaces;
  }
}
.noAnswerWrapper {
  flex-wrap: nowrap;
  align-items: center;
  min-height: 50px;
  [class~='ant-col']:first-child {
    min-width: 161.5px;
    margin-right: 20px;
  }
  .explanation {
    font-size: 16px;
    overflow-wrap: break-word;
  }
}
.content {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 95%;
  margin: 9px auto 0 auto;
  background: #fff;
  [class~='ant-card'] {
    border: 1px solid #B1B1B1;
    [class~='ant-card-head'] {
      border-bottom: none;
    }
  }
  .answerExplain {
    height: 306px;
    margin-bottom: 10px;
  }
  .answer {
    display: flex;
    flex-direction: column;
    .no {
      align-items: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      height: 128px;
      border: 1px solid #B1B1B1;
      & > span {
        border: 1px solid #B1B1B1;
        padding: 5px 16px;
        height: 36px;
      }
    }
  }
}
.wrapper {
  flex-wrap: nowrap;
  align-items: center;
  min-height: 50px;
  [class~='ant-col']:first-child {
    min-width: 161.5px;
    margin-right: 20px;
  }
  .explanation {
    font-size: 16px;
    overflow-wrap: break-word;
  }
}
.explanationWrapper {
  font-size: 14px;
  overflow-wrap: break-word;
}
.noAssessment {
  margin: 10px;
  height: calc(100vh - 80px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.container {
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
}
