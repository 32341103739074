.ant-input-number-handler-wrap{
  display: none;
}
.button{
  width: 70%;
}
.align{
  margin-top: 25%;
  text-align: center;
}
.titleAlign{
  margin-bottom: 5% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 450px;
}
.ant-form-item-label{
  text-align: left !important;
}

.formItemAlignment{
  margin: 0;
}
.row {
  & > div {
    margin-bottom: 20px;
    & > div > div > :nth-of-type(2) {
      position: absolute;
      bottom: -25px;
    }
  }
}

.input {
  border-radius: 5px;
  height: 40px;
}
.iconformItemAlignment{
  color: #ccc;
}
.buttonAlign{
  width: 100%;
}
.title {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  & > div:nth-child(1) > div > div {
    white-space: pre;
  }
}
.authForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 450px;
}
.inputNumber {
  & > :first-child {
    display: none;
  }
  & > :nth-of-type(2) > input {
    padding-left: 14px;
  }
}

.container {
  height: calc(100% - 56px);
}